<template>
  <tr class="border-b">
    <th
      class="p-3 px-5"
      v-for="(header, index) in headers ? headers : tableHeaders"
      :key="index"
    >
      {{ header }}
    </th>
  </tr>
</template>

<script>
export default {
  inject: ["headers"],
  props: {
    tableHeaders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
