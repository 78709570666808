<template>
  <router-link v-if="route" :to="route">
    <div
      class="
        rounded-2xl
        h-52
        flex
        justify-center
        items-center
        w-52
        bg-lightGrey
        duration-500
        cursor-pointer
        shadow-md
        hover:shadow-2xl hover:text-orange
        text-grey
      "
    >
      <div class="flex flex-col items-center justify-center">
        <span class="material-icons text-5xl"> {{ icon }} </span>
        <p class="text-center text-sm font-medium mb-4 mt-4 text-grey">
          {{ title }}
        </p>
      </div>
    </div>
  </router-link>
  <div
    v-else
    class="
      rounded-2xl
      h-52
      flex
      justify-center
      items-center
      w-52
      bg-lightGrey
      duration-500
      cursor-pointer
      shadow-md
      hover:shadow-2xl hover:text-orange
      text-grey
    "
  >
    <div class="flex flex-col items-center justify-center">
      <span class="material-icons text-5xl"> {{ icon }} </span>
      <p class="text-center text-sm font-medium mb-4 mt-4 text-grey">
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    title: { type: String },
    route: { type: Object },
  },
};
</script>
