<template>
  <tbody>
    <table-header :tableHeaders="tableHeaders"></table-header>
    <slot></slot>
  </tbody>
</template>

<script>
import tableHeader from "./tableHeader.vue";
export default {
  components: {
    tableHeader,
  },
  props: {
    tableHeaders: {
      type: [Array, Object],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedComponent: this.title,
    };
  },
};
</script>
