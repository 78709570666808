<template>
  <div class="flex items-center text-teal mt-3 mb-3 cursor-pointer w-24" @click="reRoute">
    <span class="material-icons-outlined"> arrow_back </span>
    go Back
  </div>
  <div class="flex mx-2 gap-x-2">
    <div class="
        w-1/4
        rounded-xl
        p-3
        shadow-md
        hover:shadow-2xl
        transition-all
        duration-500
        cursor-pointer
      " :class="!toggle
        ? 'bg-white text-grey'
        : 'bg-gradient-to-tr from-lightGreen to-teal text-white'
        ">
      <div class="flex justify-between items-center">
        <label for="switchInterviews" class="cursor-pointer">
          <span class="font-medium"> Switch </span>
        </label>
        <div class="">
          <div class="relative inline-block w-10 mr-2 align-middle select-none">
            <input type="checkbox" v-model="toggle" name="toggle" id="switchInterviews" class="
                checked:bg-teal
                outline-none
                focus:outline-none
                right-4
                checked:right-0
                duration-200
                ease-in
                absolute
                block
                w-6
                h-6
                rounded-full
                bg-white
                border-4
                appearance-none
                cursor-pointer
              " />
            <label for="Blue" class="
                block
                overflow-hidden
                h-6
                rounded-full
                bg-lightGrey
                cursor-pointer
              ">
            </label>
          </div>
        </div>
      </div>
      <div class="h-full flex justify-center pt-11" @click="toggleMethod()">
        <div class="">
          <div class="material-icons text-9xl mx-3">interpreter_mode</div>
          <div>
            <p v-if="!toggle">Completed Intreviews</p>
            <p v-else>Upcoming Interviews</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Up-comping Intervies -->
    <div v-if="!interviewsAreLoading" class="rounded-xl w-3/4 m-auto bg-teal transition-all duration-500">
      <h3 v-if="!toggle" class="text-lg text-white text-center">
        Upcoming Interviews
      </h3>
      <h3 v-else class="text-lg text-white text-center">Recent Intreviews</h3>

      <div>
        <vueper-slides v-if="!interviewsAreLoading" fixed-height="320px" :breakpoints="breakpoints" class="no-shadow"
          :visible-slides="3" :dragging-distance="20" :gap="-2" :bullets-outside="false" :arrows-outside="false">
          <vueper-slide v-for="interview in getUpcomingInterviews" :key="interview.id">
            <template v-slot:content>
              <slide :icon="'meeting_room'" :title="interview.user.email" />
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>
    <div v-else class="rounded-xl w-3/4 m-auto transition-all duration-500">
        <table-skeleton></table-skeleton>
    </div>
  </div>
  <!-- TABLE -->
  <div class="flex flex-col justify-center items-center space-y-6 py-3 w-full px-2">
    <!-- OPEN OFFERS TITLE -->
    <div class="bg-lightGrey rounded-xl shadow-md w-full">
      <div class="pl-4 py-4">
        <h2 v-if="!toggle" class="text-grey font-bold">Scheduled Interviews</h2>
        <h2 v-else class="text-grey font-bold">Completed Interviews</h2>
      </div>
      <!-- <template v-if="!isLoading"> -->
      <template v-if="!interviewsAreLoading">
        <table class="text-center w-full text-grey my-2">
          <dynamic-table :showTitle="false" title="">
            <interview-details-table-item :data="getUpcomingInterviews" :link_1="'hq-employee-details'"
              :link_2="'hq-opened-interview'" :myParameter_1="'userId'" :myParameter_2="'interviewId'">
            </interview-details-table-item>
          </dynamic-table>
        </table>
        <my-pagination :options="options" v-model="nullPage" :records="20" :per-page="10"
          @paginate="myCallback($event, null)">
        </my-pagination>
      </template>
      <no-data-found v-else />
    </div>
    <!-- <template v-else>
      <table-skeleton />
    </template> -->
  </div>
</template>

<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
import slide from "@/components/ui/baseComponents/slide.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
// import tableSkeleton from "@/components/ui/baseComponents/skeletons/tableSkeleton.vue";
import dynamicTable from "@/components/ui/baseComponents/Tables/greyTable.vue";
import interviewDetailsTableItem from "@/components/ui/baseComponents/Tables/HQ/interviewDetailsTableItem.vue";
export default {
  provide() {
    return { headers: this.tableHeaders };
  },
  created() {
    this.loadInterviews();
  },
  components: {
    VueperSlides,
    VueperSlide,
    slide,
    noDataFound,
    // tableSkeleton,
    dynamicTable,
    interviewDetailsTableItem,
  },
  data() {
    return {
      toggle: false,
      interviewsAreLoading: false,
      breakpoints: {
        1200: {
          slideRatio: 1 / 4,
        },
        900: {
          slideRatio: 1 / 2,
        },
        600: {
          slideRatio: 1 / 1,
          arrows: false,
          bulletsOutside: true,
        },
      },
      options: {
        template: markRaw(customPaginate),
      },
      nullPage: 1,
      tableHeaders: [
        "Candidate Name",
        "Interview Date",
        "Time",
        "Location",
        "Application Status",
        "Role Applied For",
        "Application Manager",
        // "View Interview",
      ],
      tableData: [
        {
          id: 1,
          candidate_name: "Peter Parker",
          interview_date: "01.02.2000",
          interview_time: "9:00am",
          location: "Head Office Hinckley",
          application_status: "Offer Made",
          role_applied_for: "HQ Manager PA",
          application_manager: "Bill Gates",
        },
        {
          id: 2,
          candidate_name: "Matt Murdock",
          interview_date: "10.12.2001",
          interview_time: "9:00am",
          location: "Zoom Meeting",
          application_status: "Accepted",
          role_applied_for: "Receptionist",
          application_manager: "Mark Zuckerberg",
        },
      ],
    };
  },
  methods: {
    reRoute() {
      this.$router.back();
    },
    toggleMethod() {
      this.toggle = !this.toggle;
    },
    myCallback(e) {
      console.log(e);
    },
    async loadInterviews() {
      this.interviewsAreLoading = true;
      try {
        await this.$store.dispatch("HQnewHire/fetchAllInterviews");
        this.interviewsAreLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
  computed: {
    getUpcomingInterviews() {
      if (!this.toggle) {
        const data = this.$store.getters["HQnewHire/getAllInterviews"];
        return data.data.filter((completed) => completed.is_completed === 0);
      } else {
        const data = this.$store.getters["HQnewHire/getAllInterviews"];
        return data.data.filter((completed) => completed.is_completed === 1);
      }
    },
    // getInCompleteInterviews() {
    //   const filteredGetter = this.getUpcomingInterviews;
    //   const something = filteredGetter.filter(
    //     (completed) => completed.is_completed === 0
    //   );
    //   console.log("SOMETHING: ", something);
    //   return something;
    // },
  },
};
</script>

<style scoped>
.vueperslide {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  transition: 0.3s ease-in-out;
  height: 85%;
}

.vueperslide--active {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}
</style>
