<template>
  <tr
    v-for="item in data"
    :key="item.id"
    class="border-b hover:bg-orange-100 bg-gray-100"
  >
    <td class="p-3 px-5 cursor-pointer hover:underline">
      <router-link
        :to="{
          name: link_1,
          params: { [myParameter_1]: item.user.id },
        }"
      >
        <p v-if="item.user">{{ item.user.email }}</p>
      </router-link>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.date">{{ item.date }}</p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.time">{{ item.time }}</p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.location">{{ item.location }}</p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.application_status">{{ item.application_status }}</p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.hiring_request">{{ item.hiring_request.job_title }}</p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.hiring_request">
        {{ item.hiring_request.application_manager }}
      </p>
    </td>

    <!-- <td
      class="p-3 px-5 cursor-pointer hover:scale-110 transform-gpu transition duration-100 ease-linear"
    >
      <router-link
        :to="{
          name: link_2,
          params: { [myParameter_2]: item.id },
        }"
      >
        <span class="material-icons"> more_horiz </span>
      </router-link>
    </td> -->
  </tr>
</template>

<script>
import { onMounted } from "vue";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    link_1: {
      type: String,
    },
    link_2: {
      type: String,
    },
    myParameter_1: {
      type: String,
    },
    myParameter_2: {
      type: String,
    },
  },
  setup(props) {
    onMounted(() => {
      {
        console.log("SZALA", props);
      }
    });
  },
};
</script>
